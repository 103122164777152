@keyframes shake {
    0% {
        transform: rotateZ(-30deg);
    }
    25% {
        transform: rotateZ(30deg);
    }
    50% {
        transform: rotateZ(30deg);
    }
    75% {
        transform: rotateZ(-30deg);
    }
}

.call_icon{
    transform: rotateZ(-30deg);
    animation: shake .5s;
    animation-iteration-count: infinite;
}