$zing-Red: #c7001c;
$zing-Slate: #2b3040;
$zing-Grey: #4a4a4a;
$zing-MidGrey: #b2b2b2;
$zing-LightGrey: #dadada;
$zing-Yolk: #fab400;
$zing-Dragonfruit: #de007e;
$zing-Avocado: #9bbd1e;

// BULMA OVERRIDES
$primary: $zing-Slate;
$grey-light: $zing-LightGrey;
$family-sans-serif: "CeraPro-Regular";
$danger: $zing-Dragonfruit;
$success: $zing-Avocado;
$warning: $zing-Yolk;

@import "bulma/bulma.sass";

$fa-font-path: "../webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";

$table-background-color: $light;
$table-cell-padding: 0.1rem 0.1rem;
$table-cell-border-width: 0 0 0;

$button-disabled-opacity: 1;
$button-padding-horizontal: 0.1rem;
$button-padding-vertical: 0.1rem;

$box-padding: 1%;
$box-background-color: $light