@font-face {
  font-family: "CeraPro-Medium";
  src: url("https://brass-earwig-2836.twil.io/assets/CeraPro-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "CeraPro-Regular";
  src: url("https://brass-earwig-2836.twil.io/assets/CeraPro-Regular.woff2") format("woff2");
}

html {
  overflow-y: hidden !important;
}

body {
  margin: 0;
  font-family: "CeraPro-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "CeraPro-Medium";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
