@import "../styles/base.scss";
@import "bulma/sass/components/level.sass";

$header-height: 4rem;
$header-vertical-padding: 1rem;
$header-horizontal-padding: 2rem;
$logo-height: 2.5rem;

.header-layout {
  height: 100%;

  .level.zing-header {
    background-color: $primary;
    color: $light;
    padding: $header-vertical-padding $header-horizontal-padding;
    margin-bottom: 0;
    height: $header-height;

    img {
      height: $logo-height;
    }

    .level-right .level-item{
      margin-left: 1rem;
    }
  }

  > .container {
    height: calc(
      100% - #{$header-height} - #{$header-vertical-padding} - #{$header-vertical-padding}
    );
    margin: 0 0;
    width: 100%;
    max-width: 100%;
  }
}
