@import "../styles/base.scss";
@import "bulma/sass/grid/columns.sass";
@import "bulma/sass/grid/tiles.sass";

.sidebar-layout {
    .centre-column {
        background: $grey-light;
        align-self: center;
    }
    .tile {
        .title {
            font-size: 1.5rem;
        }
    }
    .tile.is-ancestor {
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 1rem;
        padding-left: 1rem;
    }
}