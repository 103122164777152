@import "../../styles/base.scss";
@import "bulma/sass/elements/table.sass";
@import "bulma/sass/elements/progress.sass";
@import "bulma/sass/grid/tiles.sass";
@import "bulma/sass/elements/button.sass";
@import "bulma/sass/elements/box.sass";
@import "bulma/sass/elements/tag.sass";

.tile.is-child.box {
    padding: 0.1rem;
}

.loading-msg {
    text-align: center;
    justify-content: center;
    width: 100%;
    .loading-text {
        color: $danger;
        font-size: 1.5rem;
    }
    .progress {
        width: 80%;
        margin: auto;
    }
}

.error-msg {
    color: $danger;
    font-size: 1.5rem;
}

.tile.is-ancestor {
    flex-wrap: wrap;
}

.metric-name {
    text-overflow: ellipsis;
    max-height: 1rem;
    max-width: 10rem;
    overflow: hidden;
    white-space: nowrap;
}

.goal-name {
    max-width: 18rem;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 0.5rem;
}

.title {
    text-align: center;
}

.content {
    align-content: center;
}

.tile.is-child {
    .title {
        margin: 0;
    }
}

.tile.is-ancestor.content-container {
    justify-content: center;
    margin: 0;
    padding: 0;
}

.button-cell {
    text-align: center;
    button {
        width: 100%;
    }
}

.status-tag {
    width: 100%;
    max-width: 6rem;
}

.status-cell {
    text-align: right;
}

.panel-heading {
    font-size: 1.5rem;
}