@import "../styles/base.scss";

.user-activity-bar {
  height: 100%;

  .box {
    padding: 1rem;
    height: 100%;
    margin-left: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    > hr {
      width: 100%;
      height: 2px;
      background-color: $zing-MidGrey;
      border: none;
    }

    > h3 {
      margin: 0;
      margin-bottom: 1rem;
    }

    .avatar-ring {
      margin-bottom: 0.5rem;
    }
  }
}

.danger {
  color: $danger;
}
