@import "../styles/base.scss";

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: circle(43%);
  background: $light;
}

.avatar-ring {
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: circle(50%);
}

.notify {
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.available {
  background-color: $zing-Avocado;
}

.busy {
  background-color: $zing-Red;
}

.offline {
  background-color: $zing-Grey;
}

@keyframes pulse {
  0% {
    background-color: initial;
  }
  50% {
    background-color: $zing-Red;
  }
  100% {
    background-color: initial;
  }
}
