@import "../styles/base.scss";

.clock {
  text-align: right;
  width: 7rem;

  .level,
  .level:not(:last-child) {
    margin-bottom: 0;
  }
}
